function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.controller.isLoading)?_c('LoadingOverlay'):_vm._e(),_c('ModalConfirm',{attrs:{"data-testid":"adjustment-request-topup-saldo-detail__modal-confirmation","visible":_vm.modalConfirm,"isDisabled":_vm.controller.isLoading,"title":_vm.MODAL_CONFIRM_REQUEST_TOPUP.title,"subtitle":_vm.MODAL_CONFIRM_REQUEST_TOPUP.description,"phoneNumber":_vm.phoneNumberUser,"amount":_vm.form.amount,"textAction":"Yes, Request","balanceTypeAdjustment":_vm.form.balanceTypeAdjustment === _vm.constantsData.EnumTopUpBalanceType.PLUS
        ? 'Penambahan'
        : 'Pengurangan',"note":_vm.form.note},on:{"cancel":function () {
        _vm.modalConfirm = false
      },"action":_vm.onSubmit}}),_c('Modal',{attrs:{"data-testid":"adjustment-request-topup-saldo-detail__modal-success","visible":_vm.modalSuccess,"type":"success","modalContainer":"modal-container-md","title":_vm.MODAL_SUCCESS_REQUEST_TOPUP.title,"body":_vm.MODAL_SUCCESS_REQUEST_TOPUP.description,"textButton":"Okay"},on:{"change":function () {
        _vm.$router.push({ name: 'RequestTopupPage' })
      }}}),_c('ModalDetail',{attrs:{"data-testid":"adjustment-request-topup-saldo-detail__modal-detail","visible":_vm.modalDetail,"notes":_vm.currentNotes},on:{"cancel":function () {
        _vm.modalDetail = false
      }}}),_c('div',{staticClass:"section-form"},[_c('div',{staticClass:"wrapper-form-request-topup"},[_c('h2',{staticClass:"title-request-topup"},[_vm._v("Form Request Saldo")]),_c('AdjustmentTextInput',{attrs:{"data-testid":"adjustment-request-topup-saldo-detail__input-amount","fieldLabel":"Masukkan Nominal Saldo","fieldDescription":_vm.form.balanceTypeAdjustment ===
          _vm.constantsData.EnumTopUpBalanceType.PLUS
            ? 'Maksimal Rp2.000.000'
            : 'Tidak ada maksimal Saldo yang ditentukan',"prefixCurrency":"IDR","type":"currency","placeholder":"Nominal Saldo"},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}}),_c('div',{staticClass:"mb-4"},[_vm._m(0),_c('div',{staticClass:"flex gap-x-12 mt-1"},[_c('RadioButton',{attrs:{"data-testid":"adjustment-request-topup-saldo-detail__input-balance-credit","customClassLabel":'font-normal text-sm text-black ml-3',"label":"Penambahan","name":_vm.constantsData.EnumTopUpBalanceType.PLUS,"inputValue":_vm.constantsData.EnumTopUpBalanceType.PLUS},model:{value:(_vm.form.balanceTypeAdjustment),callback:function ($$v) {_vm.$set(_vm.form, "balanceTypeAdjustment", $$v)},expression:"form.balanceTypeAdjustment"}}),_c('RadioButton',{attrs:{"data-testid":"adjustment-request-topup-saldo-detail__input-balance-debit","customClassLabel":'font-normal text-sm text-black ml-3',"label":"Pengurangan","name":_vm.constantsData.EnumTopUpBalanceType.MINUS,"inputValue":_vm.constantsData.EnumTopUpBalanceType.MINUS},model:{value:(_vm.form.balanceTypeAdjustment),callback:function ($$v) {_vm.$set(_vm.form, "balanceTypeAdjustment", $$v)},expression:"form.balanceTypeAdjustment"}})],1)]),_c('AdjustmentTextInput',{attrs:{"data-testid":"adjustment-request-topup-saldo-detail__input-notes","fieldLabel":"Notes","type":"textarea","placeholder":"Masukkan catatan kepentingan top up saldo","withCounter":true,"maxCount":255},model:{value:(_vm.form.note),callback:function ($$v) {_vm.$set(_vm.form, "note", $$v)},expression:"form.note"}}),_c('Button',{staticClass:"button-submit",style:(_vm.$v.form.$invalid ? 'opacity: 1; background-color: #A7A8AA;' : ''),attrs:{"data-testid":"adjustment-request-topup-saldo-detail__button-submit","customPadding":"","disabled":_vm.$v.form.$invalid},on:{"click":function () {
            _vm.modalConfirm = true
          }}},[_vm._v(" Submit ")])],1),_c('div',{staticClass:"card-notif"},[_c('div',[_c('IconInfoCircle')],1),_vm._m(1)])]),(_vm.dataRequestTopHistoryList.length > 0)?_c('div',{staticClass:"wrapper-table-request-topup"},[_c('div',{staticClass:"section-title"},[_c('span',{staticClass:"text-user-activity"},[_vm._v("User Activity Log ("+_vm._s(_vm.phoneNumberUser)+")")]),_c('span',{staticClass:"button-show-hide",attrs:{"data-testid":"adjustment-request-topup-saldo-detail__button-show-hide-history-log"},on:{"click":_vm.onShowHide}},[_vm._v(" "+_vm._s(_vm.showTable ? 'Hide' : 'Open')+" ")])]),(_vm.showTable)?_c('DataTableV2',{attrs:{"data-testid":"adjustment-request-topup-saldo-detail__table-history-log","isLoading":false,"headers":_vm.headers,"dataItems":_vm.dataRequestTopHistoryList},scopedSlots:_vm._u([{key:"5",fn:function(ref){
          var rest = objectWithoutProperties( ref, [] );
          var props = rest;
return [_c('div',{staticClass:"wrap-text-notes"},[_c('span',{staticClass:"text-note"},[_vm._v(_vm._s(props.data.value))]),_c('span',{staticClass:"btn-view-detail",attrs:{"data-testid":("adjustment-request-topup-saldo-detail__button-view-notes-" + (props.index))},on:{"click":function () { return _vm.seeNotes(props.index); }}},[_vm._v("View detail")])])]}}],null,false,2590878463)}):_vm._e(),(_vm.showTable)?_c('div',{staticClass:"mt-4"},[_c('PaginationNav',{attrs:{"page":_vm.parameters.page,"perPage":_vm.parameters.perPage,"totalItem":_vm.controller.paginationData.totalItem},on:{"input":function () {
            _vm.fetchHistory()
          },"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function () {
            _vm.fetchHistory()
          })($event)}},model:{value:(_vm.parameters.page),callback:function ($$v) {_vm.$set(_vm.parameters, "page", $$v)},expression:"parameters.page"}})],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"font-semibold gap-1"},[_c('span',{staticClass:"text-bgMatterhorn text-sm"},[_vm._v("Transaksi")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"info-notif"},[_c('h2',{staticClass:"title-info-notif"},[_vm._v("Info Penting")]),_c('ul',[_c('li',[_vm._v(" Pastikan saldo yang di Top Up sesuai dengan pemintaan seharusnya ")]),_c('li',[_vm._v(" Untuk penambahan saldo, maksimal jumlah di Top Up adalah Rp2.000.000 dan memiliki batas 1 user/hari ")]),_c('li',[_vm._v(" Untuk pengurangan saldo, tidak ada maksimal Saldo yang ditentukan ")])])])}]

export { render, staticRenderFns }